var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "item", staticClass: "vue-grid-layout", style: _vm.mergedStyle },
    [
      _vm._t("default"),
      _c("grid-item", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isDragging,
            expression: "isDragging",
          },
        ],
        staticClass: "vue-grid-placeholder",
        attrs: {
          x: _vm.placeholder.x,
          y: _vm.placeholder.y,
          w: _vm.placeholder.w,
          h: _vm.placeholder.h,
          i: _vm.placeholder.i,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }