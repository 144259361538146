<!--
 * @Author: your name
 * @Date: 2021-01-29 18:06:52
 * @LastEditTime: 2022-07-13 15:10:55
 * @LastEditors: faf
 * @Description: In User Settings Edit
 * @FilePath: \aiparkcity-acb\src\views\homeConfig\homeConfig.vue
-->
<template>
  <div class="homeContent">
    <div class="top">
      <div class="titleBg">
        <span class="title">智慧泊车管理平台</span>
      </div>
    </div>
    <div class="homeConfig">
      <div class="left">
        <div class="draggable-wrapper" id="draggable-wrapper">
          <div class="map-wrapper">
            <grid-layout
              ref="gridlayout"
              :layout.sync="testLayout"
              :col-num="24"
              :row-height="rowHeight"
              :is-draggable="true"
              :is-resizable="true"
              :vertical-compact="true"
              :prevent-collision="false"
              :use-css-transforms="true"
              :autoSize="false"
            >
              <grid-item
                class="gridItem"
                v-for="item in testLayout"
                :key="item.i"
                :style="{
                  background: `url(${item.picUrl}) 50% 50% / 100% 100% no-repeat`,
                  touchAction: 'none',
                  boxSizing: 'border-box',
                }"
                :static="item.static"
                :maxW="item.maxW"
                :maxH="item.maxH"
                :minH="item.minH"
                :minW="item.minW"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
              >
                <div class="remove" v-if="!item.static">
                  <i class="el-icon-delete removeBtn" @click="removeItem(item.i)"></i>
                </div>
              </grid-item>
            </grid-layout>
          </div>
        </div>
        <div class="wrapperFoot">
          <el-button
            v-if="this.$route.meta.authority.button.edit"
            type="primary"
            size="medium"
            @click="saveLayout"
            >{{ $t('button.preservation') }}</el-button
          >
          <el-button v-if="this.$route.meta.authority.button.query" size="medium" @click="cancle"
            >取消</el-button
          >
        </div>
      </div>
      <div class="right">
        <div class="config-wrapper">
          <div class="search-wrapper">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item :label="$t('searchModule.classification')">
                <el-select
                  v-model="formInline.classify"
                  style="width: 100px"
                  size="mini"
                  placeholder="选择分类"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option label="饼图" value="pie"></el-option>
                  <el-option label="折线图" value="line"></el-option>
                  <el-option label="汇总统计" value="collect"></el-option>
                  <el-option label="其他" value="other"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关键字">
                <el-input v-model="formInline.keyword" style="width: 110px" size="mini"></el-input>
              </el-form-item>
              <el-form-item label="大小">
                <el-radio-group
                  class="radioGroup"
                  text-color="#fff"
                  fill="#0076f5"
                  v-model="formInline.size"
                  size="mini"
                >
                  <el-radio-button label="5X3"></el-radio-button>
                  <el-radio-button label="5X5"></el-radio-button>
                  <el-radio-button label="8X5"></el-radio-button>
                  <el-radio-button label="10X5"></el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit" size="mini">{{ $t('button.search') }}</el-button>
                <el-button type="primary" @click="reset" size="mini">{{ $t('button.reset') }}</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="config-list">
            <GeminiScrollbar>
              <el-row :gutter="10">
                <el-col
                  :span="item.span"
                  v-for="item in configList"
                  :key="item.id"
                  v-if="item.showItem === true"
                >
                  <div class="configItem" v-if="item.showItem">
                    <div
                      @drag="drag($event, item)"
                      @dragend="dragend($event, item)"
                      draggable="true"
                      unselectable="on"
                      :style="{
                        background: `url(${item.picUrl}) 50% 50% / 100% 100% no-repeat`,
                        height: '100%',
                      }"
                    ></div>
                  </div>
                  <p class="title">{{ item.title }}</p>
                </el-col>
              </el-row>
            </GeminiScrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GridLayout from "./GridLayout";
import GridItem from "./GridItem";

let mouseXY = { x: null, y: null };
let DragPos = { x: null, y: null, w: 1, h: 1, i: null };
export default {
  data() {
    return {
      rowHeight: 40,
      dynamicMenuId: "",
      testLayout: [
        {
          x: 0,
          y: 0,
          w: 14,
          h: 8,
          i: 0,
          resizable: false,
          draggable: false,
          picUrl: require("./img/map.png"),
          name: "map",
          static: true,
          minW: 14,
          minH: 8,
          maxW: 14,
          maxH: 8,
        },
      ],
      isEdit: true,
      formInline: {
        classify: "",
        keyword: "",
        size: "",
      },
      configList: [
        {
          x: 14,
          y: 0,
          w: 5,
          h: 3,
          i: 2,
          minW: 5,
          minH: 3,
          maxW: 6,
          maxH: 5,
          resizable: true,
          draggable: true,
          showItem: true,
          static: false,
          title: "实缴率",
          type: "5X3,6X5",
          span: 12,
          picUrl: require("./img/pie1.png"),
          name: "",
          classify: "pie",
        },
        {
          x: 14,
          y: 0,
          w: 5,
          h: 3,
          i: 3,
          minW: 5,
          minH: 3,
          maxW: 6,
          maxH: 5,
          resizable: true,
          draggable: true,
          showItem: true,
          static: false,
          title: "昼夜停车时长对比",
          type: "5X3,6X5",
          span: 12,
          picUrl: require("./img/pie3.png"),
          name: "",
          classify: "pie",
        },
        {
          x: 14,
          y: 0,
          w: 10,
          h: 5,
          i: 4,
          minW: 10,
          minH: 5,
          maxW: 10,
          maxH: 5,
          resizable: true,
          draggable: true,
          showItem: true,
          static: false,
          title: "汇总统计",
          type: "10X5",
          span: 24,
          picUrl: require("./img/statistics.png"),
          name: "",
          classify: "collect",
        },
        {
          x: 0,
          y: 8,
          w: 8,
          h: 5,
          i: 5,
          minW: 8,
          minH: 5,
          maxW: 12,
          maxH: 5,
          resizable: true,
          draggable: true,
          showItem: true,
          static: false,
          title: "停车趋势",
          type: "8X5,12X5",
          span: 24,
          picUrl: require("./img/line2.png"),
          name: "",
          classify: "line",
        },
        {
          x: 8,
          y: 8,
          w: 8,
          h: 5,
          i: 6,
          minW: 8,
          minH: 5,
          maxW: 12,
          maxH: 5,
          resizable: true,
          draggable: true,
          showItem: true,
          static: false,
          title: "城市收入对比",
          type: "8X5,12X5",
          span: 24,
          picUrl: require("./img/line1.png"),
          name: "",
          classify: "line",
        },
        {
          x: 16,
          y: 8,
          w: 8,
          h: 5,
          i: 7,
          minW: 8,
          minH: 5,
          maxW: 12,
          maxH: 5,
          resizable: true,
          draggable: true,
          showItem: true,
          static: false,
          title: "今日停车高低峰",
          type: "8X5,12X5",
          span: 24,
          picUrl: require("./img/line3.png"),
          name: "",
          classify: "line",
        },
        {
          x: 14,
          y: 0,
          w: 5,
          h: 3,
          i: 8,
          minW: 5,
          minH: 3,
          maxW: 6,
          maxH: 5,
          resizable: true,
          draggable: true,
          showItem: true,
          static: false,
          title: "泊位饱和度",
          type: "5X3,6X5",
          span: 12,
          picUrl: require("./img/pie2.png"),
          name: "",
          classify: "pie",
        },
        {
          x: 14,
          y: 0,
          w: 5,
          h: 3,
          i: 9,
          minW: 5,
          minH: 3,
          maxW: 6,
          maxH: 5,
          resizable: true,
          draggable: true,
          showItem: true,
          static: false,
          title: "会员支付占比",
          type: "5X3,6X5",
          span: 12,
          picUrl: require("./img/pie4.png"),
          name: "",
          classify: "pie",
        },
        {
          x: 14,
          y: 0,
          w: 5,
          h: 5,
          i: 10,
          minW: 5,
          minH: 5,
          maxW: 6,
          maxH: 5,
          resizable: true,
          draggable: true,
          showItem: true,
          static: false,
          title: "停车压力车场Top5",
          type: "6X5,5X5",
          span: 12,
          picUrl: require("./img/parkTop1.png"),
          name: "",
          classify: "other",
        },
        {
          x: 14,
          y: 0,
          w: 5,
          h: 5,
          i: 11,
          minW: 5,
          minH: 5,
          maxW: 6,
          maxH: 5,
          resizable: true,
          draggable: true,
          showItem: true,
          static: false,
          title: "车场收入Top5",
          type: "5X5,6X5",
          span: 12,
          picUrl: require("./img/parkTop2.png"),
          name: "",
          classify: "other",
        },
        {
          x: 14,
          y: 0,
          w: 5,
          h: 5,
          i: 12,
          minW: 5,
          minH: 5,
          maxW: 6,
          maxH: 5,
          resizable: true,
          draggable: true,
          showItem: true,
          static: false,
          title: "车辆进出场信息",
          type: "5X5,6X5",
          span: 12,
          picUrl: require("./img/parkTop3.png"),
          name: "",
          classify: "other",
        },
        {
          x: 14,
          y: 0,
          w: 5,
          h: 5,
          i: 13,
          minW: 5,
          minH: 5,
          maxW: 6,
          maxH: 5,
          resizable: true,
          draggable: true,
          showItem: true,
          static: false,
          title: "停车难易指数",
          type: "5X5,6X5",
          span: 12,
          picUrl: require("./img/radar1.png"),
          name: "",
          classify: "other",
        },
      ],
    };
  },
  components: {
    GridLayout,
    GridItem,
  },
  methods: {
    drag(event, item) {
      // mouseXY = item
      let parentRect = document.getElementById("draggable-wrapper").getBoundingClientRect();
      let mouseInGrid = false;
      if (
        mouseXY.x > parentRect.left &&
        mouseXY.x < parentRect.right &&
        mouseXY.y > parentRect.top &&
        mouseXY.y < parentRect.bottom
      ) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true && this.testLayout.findIndex((item) => item.i === "drop") === -1) {
        console.log(this.testLayout.length + (this.colNum || 12));
        this.testLayout.push({
          x: (this.testLayout.length * 2) % (this.colNum || 12),
          y: this.testLayout.length + (this.colNum || 12), // puts it at the bottom
          w: 1,
          h: 1,
          i: "drop",
        });
      }
      let index = this.testLayout.findIndex((item) => item.i === "drop");
      if (index !== -1) {
        try {
          this.$refs.gridlayout.$children[this.testLayout.length].$refs.item.style.display = "none";
        } catch {}
        let el = this.$refs.gridlayout.$children[index];
        el.dragging = { top: mouseXY.y - parentRect.top, left: mouseXY.x - parentRect.left };
        // let newPos = el.calcXY(mouseXY.y - parentRect.top - el.$el.offsetHeight, mouseXY.x - parentRect.left - el.$el.offsetWidth / 2);
        let newPos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);
        if (mouseInGrid === true) {
          this.$refs.gridlayout.dragEvent("dragstart", "drop", newPos.x, newPos.y, 1, 1);
          DragPos.i = String(index);
          DragPos.x = this.testLayout[index].x;
          DragPos.y = this.testLayout[index].y;
        }
        if (mouseInGrid === false) {
          this.$refs.gridlayout.dragEvent("dragend", "drop", newPos.x, newPos.y, 1, 1);
          this.testLayout = this.testLayout.filter((obj) => obj.i !== "drop");
        }
      }
    },
    dragend(e, item) {
      let parentRect = document.getElementById("draggable-wrapper").getBoundingClientRect();
      let mouseInGrid = false;
      if (
        mouseXY.x > parentRect.left &&
        mouseXY.x < parentRect.right &&
        mouseXY.y > parentRect.top &&
        mouseXY.y < parentRect.bottom
      ) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true) {
        this.testLayout = this.testLayout.filter((obj) => obj.i !== "drop");
        setTimeout(() => {
          this.testLayout.push({
            ...item,
            x: DragPos.x,
            y: DragPos.y,
          });
          console.log(parentRect.height / this.rowHeight);
          let dropTop =
            (parentRect.height / this.rowHeight) * DragPos.y +
            DragPos.h * (parentRect.height / this.rowHeight);
          let parentRectHeight = parentRect.height;
          console.log(dropTop, parentRectHeight);
          if (dropTop > parentRectHeight) return;
          this.$refs.gridlayout.dragEvent("dragend", "drop", DragPos.x, DragPos.y, 1, 1);
        }, 300);
        try {
          this.$refs["gridlayout"].$children[this.testLayout.length].$refs.item.style.display =
            "block";
        } catch {}
        this.configList = this.configList.map((val) => {
          if (val.i === item.i) {
            val.showItem = false;
          }
          return val;
        });
      } else {
        this.$refs.gridlayout.dragEvent("dragend", "drop", DragPos.x, DragPos.y, 1, 1);
        this.testLayout = this.testLayout.filter((obj) => obj.i !== "drop");
      }
    },
    onSubmit() {
      let formInline = { ...this.formInline };
      // 初始化数据
      let list = this.configList.map((val) => {
        val.showItem = true;
        return val;
      });
      // 已经添加到画布的，右侧配置列表不再出现
      for (let val of list) {
        for (let item of this.testLayout) {
          if (val.i == item.i) {
            val.showItem = false;
          }
        }
      }
      // 筛选条件为空, 返回所有数据
      if (!formInline.size && !formInline.keyword && !formInline.classify) {
        this.configList = list;
        return false;
      }
      let arr = list.map((val) => {
        if (formInline.size && val.type.indexOf(formInline.size) < 0) {
          val.showItem = false;
        }
        return val;
      });
      arr = arr.map((val) => {
        if (formInline.keyword && !val.title.includes(formInline.keyword)) {
          val.showItem = false;
        }
        return val;
      });
      arr = arr.map((val) => {
        if (formInline.classify && val.classify !== formInline.classify) {
          val.showItem = false;
        }
        return val;
      });
      this.configList = arr;
    },
    reset() {
      this.formInline = {
        classify: "",
        keyword: "",
        size: "",
      };
    },
    pushConfig(item) {
      let newItem = { ...item };
      newItem.x =
        this.testLayout[this.testLayout.length - 1].x +
        this.testLayout[this.testLayout.length - 1].w;
      this.testLayout.push(newItem);
      this.configList = this.configList.map((val) => {
        if (val.i === item.i) {
          val.showItem = false;
        }
        return val;
      });
    },
    removeItem(id) {
      this.testLayout = this.testLayout.filter((val) => {
        return val.i !== id;
      });
      this.configList = this.configList.map((val) => {
        if (val.i === id) {
          val.showItem = true;
        }
        return val;
      });
      this.onSubmit();
    },
    saveLayout() {
      this.setDate(this.testLayout);
    },
    cancle() {
      this.testLayout = [
        {
          x: 0,
          y: 0,
          w: 14,
          h: 8,
          i: 0,
          resizable: false,
          draggable: false,
          picUrl: require("./img/map.png"),
          name: "map",
          static: true,
          minW: 14,
          minH: 8,
          maxW: 14,
          maxH: 8,
        },
      ];
      this.onSubmit();
    },
    getDate() {
      this.$axios.get("/acb/2.0/dynamicMenu/getByCode/home").then((res) => {
        this.dynamicMenuId = res.value.dynamicMenuId;
        this.testLayout = JSON.parse(res.value.configInfo);
        this.onSubmit();
      });
    },
    setDate(data) {
      this.$axios
        .post("/acb/2.0/dynamicMenu/update", {
          data: {
            dynamicMenuId: this.dynamicMenuId,
            configInfo: JSON.stringify(data),
          },
        })
        .then(() => {
          this.$message.success("保存成功！");
        });
    },
  },
  created() {
    this.getDate();
  },
  mounted() {
    document.addEventListener(
      "dragover",
      function (e) {
        mouseXY.x = e.clientX;
        mouseXY.y = e.clientY;
      },
      false
    );
  },
};
</script>
<style lang="stylus" scoped>
.homeConfig
  width 100%
  height calc(100% - 50px)
  .right
    width 480px
    background #131415
    border 1px solid rgba(100,173,255,0.59)
    border-radius 5px
    margin-top 10px
    padding 20px
    box-sizing border-box
    margin-right 10px
    user-select none
  .left
    width calc(100% - 500px)
    height 100%

  .draggable-wrapper
    position relative
    width 100%
    height: calc(100% - 80px);

    .map-wrapper
      position absolute
      top 10px
      left 0
      width 100%
      height 100%
      .remove
        display none
        height 100%
        text-align center
        background #ff44334a
        line-height 100%
        position relative
        .removeBtn
          background #ff3859
          width 40px
          height 40px
          line-height 40px
          border-radius 50%
          color #fff
          font-size 20px
          position absolute
          top 50%
          left 50%
          margin-top -20px
          margin-left -20px
      .gridItem:hover
        .remove
          display block
          cursor pointer

  .wrapperFoot
    margin-top 20px
    text-align center

  .config-wrapper
    .search-wrapper
      .el-form-item
        margin-bottom 8px
    >>>.el-input__inner
      background rgba(255,255,255,0.10);

    .config-list
      width 100%
      height 520px
      overflow-x hidden
      overflow-y: auto;
      .title
        color #fff
        line-height 20px
        opacity 0.65
        padding-left 8px
      >>>.-horizontal
        display none
      .configItem
        height 120px
        margin-top 10px
        cursor pointer
        background rgba(255,255,255,0.08)
        border-radius 4px
        padding 8px
.homeContent
  background url("../../assets/img/home_bg.png") no-repeat
  background-size cover
  position absolute
  top 0
  left 0
  z-index 2
  width 100%
  height 100%
  min-width 1550px
  min-height 820px
.border
  border 1px solid #ddd

.top
  height 45px
  background url("../../assets/img/home_top_bg.png") no-repeat
  background-size cover
  .titleBg
    background url("../../assets/img/home_top_logo.png") no-repeat
    background-position 0 center
    padding-left 140px
    margin 0 auto
    width 192px
    text-align center
    .title
      height 46px
      line-height 46px
      font-size 24px
      background linear-gradient(180deg,#afecff, #4edbff)
      -webkit-background-clip text
      color transparent
      margin 0 auto
      display inline-block
.radioGroup
  >>>.el-radio-button__inner
    background none
    border: 1px solid #0076f5;
    margin-right 6px
.config-list >>>.gm-scrollbar .thumb {
  background-color: rgba(255,255,255,0.5) !important;

}
.config-list >>>.gm-scrollbar.-vertical {
  margin-right: -3px;
  margin-top: -2px;
  background-color: #000 !important;
}
</style>
